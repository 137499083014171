import textData from '@/static/textData.js' // 合作案例


const Home = {
  tabList: [
    {
      id: 1,
      tabTitle: 'VR展厅',
      img: 'https://h5.ophyer.cn/official_website/other/vrPanorama-home-warp1-img01-1.png',
      lunTitle: 'VR展厅',
      tip: '沉浸式交互体验，全面展示品牌信息',
      text: [
        '• 720°可交互全景，为您量身打造线上虚拟展厅',
        '• 全景编辑制作平台，简单操作即可生成场景',
        '• 热点、嵌入、虚拟人物等特色功能，适配更多运营场景',
      ]
    },
    {
      id: 2,
      tabTitle: 'VR带看',
      img: 'https://h5.ophyer.cn/official_website/other/vrPanorama-home-warp1-img02-1.png',
      lunTitle: 'VR带看',
      tip: '互动演示，实现VR远程带看',
      text: [
        '• 邀请多个用户共同加入联动，远程演示更方便',
        '• 打破空间和距离，无障碍交流更高效',
        '• 语音、说一说实时互动，交流本该如此简单',
      ]
    },
    {
      id: 3,
      tabTitle: '3D模型',
      img: 'https://h5.ophyer.cn/official_website/other/vrPanorama-home-warp1-img03-1.png',
      lunTitle: '3D模型',
      tip: '3D场景模型，产品体验更真实',
      text: [
        '• 支持FBX、GLTF多种模型导入，适用性更强',
        '• 3D展示产品信息，交互更强，距离更近',
      ]
    },
    {
      id: 4,
      tabTitle: '360环物',
      img: 'https://h5.ophyer.cn/official_website/other/vrPanorams-xuliezheng-01.png',
      lunTitle: '360环物',
      tip: '贴合电商场景，让产品“动”起来',
      text: [
        '• 简单拍摄即可生成360环物',
        '• 打破传统的静态图片展示，更多细节展示',
        '• 电商、汽车、展馆等多场景适用',
      ]
    },
    {
      id: 5,
      tabTitle: '虚拟导购',
      img: 'https://h5.ophyer.cn/official_website/other/vrPanorama-home-warp1-img05-1.png',
      lunTitle: '虚拟导购',
      tip: '虚拟形象导购+智能语音讲解，让场景更具亲和力',
      text: [
        '• 系统提供多款形象多款动作，适用性更强',
        '• 支持添加欢迎语，强化第一印象',
        '• 动作引导结合语音讲解，代入感更强',
      ]
    },
    {
      id: 6,
      tabTitle: '电子沙盘',
      img: 'https://h5.ophyer.cn/official_website/other/vrPanorama-home-warp1-img06-1.png',
      lunTitle: '电子沙盘',
      tip: '全局总览，想看哪里点哪里',
      text: [
        '• 提升场景辨识度，用于路线规划和导引',
        '• 不同场景可设置不同的沙盘概览图，提升用户体验感',
        '• 可根据漫游点为沙盘添加标记，浏览场景更便捷',
      ]
    }
  ],

  warp2: [
    {
      img: 'https://h5.ophyer.cn/official_website/other/vrPanorams-warp2-img1.png',
      text: '为电商商家提供在线VR运营工具'
    },
    {
      img: 'https://h5.ophyer.cn/official_website/other/vrPanorams-warp2-img2.png',
      text: '海量VR模版，快速搭建VR运营内容'
    },
    {
      img: 'https://h5.ophyer.cn/official_website/other/vrPanorams-warp2-img3.png',
      text: '灵活的运营插件，帮助商家完成快速转化'
    },
    {
      img: 'https://h5.ophyer.cn/official_website/other/vrPanorams-warp2-img4.png',
      text: '支持线上线下多种场景使用，支持引流'
    },
    {
      img: 'https://h5.ophyer.cn/official_website/other/vrPanorams-warp2-img5.png',
      text: '详细的数据报告，实时监控传播效果'
    }
  ],

  // warp3: [
  //   {
  //     img: 'https://h5.ophyer.cn/official_website/other/saas-ys-icon1.png',
  //     text: '淘宝'
  //   },
  //   {
  //     img: 'https://h5.ophyer.cn/official_website/other/saas-ys-icon2.png',
  //     text: '天猫'
  //   },
  //   {
  //     img: 'https://h5.ophyer.cn/official_website/other/saas-ys-icon3.png',
  //     text: '京东'
  //   },
  //   {
  //     img: 'https://h5.ophyer.cn/official_website/other/saas-ys-icon4.png',
  //     text: '微信'
  //   },
  //   {
  //     img: 'https://h5.ophyer.cn/official_website/other/saas-ys-icon4.png',
  //     text: '苏宁易购'
  //   }
  // ],

  anli: [
    textData.anli.weida,
    textData.anli.meilizhongguo,
    textData.anli.qinghuaxiaoyuan,
    textData.anli.haoshixian
  ]


}


const Solution = {
  tabList: [
    {
      id: 1,
      tabTitle: '电商零售',
      img: 'https://h5.ophyer.cn/official_website/other/home-tab-img01-1.png',
      lunTitle: '轻松操作打造全景云店助力运营转化',
      tip: '',
      text: [
        '超沉浸体验、虚拟导购及线上线下引流，为运营活动持续蓄力',
      ]
    },
    {
      id: 2,
      tabTitle: '房产家居',
      img: 'https://h5.ophyer.cn/official_website/other/home-tab-img02-1.png',
      lunTitle: '3D实景复刻，还原真实空间房屋信息一览无遗',
      tip: '',
      text: [
        '720°全景看房+沙盘+智能解说+同屏连线，随时随地高效看房',
      ]
    },
    {
      id: 3,
      tabTitle: '智慧文旅',
      img: 'https://h5.ophyer.cn/official_website/other/home-tab-img03-1.png',
      lunTitle: '数字科技+文化创意让景区焕发全新生命力',
      tip: '',
      text: [
        '通过AR/VR技术手段，发展沉浸式互动文旅消费内容，推动景区数字化转型升级',
      ]
    },
    {
      id: 4,
      tabTitle: '智慧教育',
      img: 'https://h5.ophyer.cn/official_website/other/home-tab-img04-1.png',
      lunTitle: 'VR沉浸式+强交互情景教学参与感更强，提升教学质量',
      tip: '',
      text: [
        '涵盖基础教育、高等教育、社会培训等多领域的智慧教育平台',
      ]
    },
    {
      id: 5,
      tabTitle: '汽车行业',
      img: 'https://h5.ophyer.cn/official_website/other/home-tab-img05-1.png',
      lunTitle: '汽车高模结合VR技术带来线上看车新体验',
      tip: '',
      text: [
        '高精度建模，720°可交互查看汽车外观及内饰支持添加留咨表单，为门店强势引流',
      ]
    },
    {
      id: 6,
      tabTitle: '餐饮行业',
      img: 'https://h5.ophyer.cn/official_website/other/VR1-1.png',
      lunTitle: '打破传统餐饮行业运营方式让你的运营活动游刃有余',
      text: [
        '适配主流餐饮、外卖平台，为线上、线下餐饮店铺强势引流',
      ]
    }
  ],

  warp3: [
    { 
      id:1, 
      src: require('@/assets/images/icon/vrPanorams-solution-warp3-img1.png'), 
      tit:'强互动 重体验',
      content:'720°沉浸式体验+数字多媒体互动打造独特品牌形象'
    },
    { 
      id:2,  
      src: require('@/assets/images/icon/vrPanorams-solution-warp3-img2.png'), 
      tit:'拓客引流、拉新' ,
      content:'微信、H5、Web多渠道流量获客，搞定运营获客难题，拉新获客不用愁'
    },
    { 
      id:3,  
      src: require('@/assets/images/icon/vrPanorams-solution-warp3-img3.png'), 
      tit:'丰富运营礼赠场景',
      content:'海量运营场景，满足活动所需，让您与客户的距离再近一点' 
    },
    { 
      id:4,  
      src: require('@/assets/images/icon/vrPanorams-solution-warp3-img4.png'), 
      // tit:'同屏连线 高效运营',
      tit:'同屏连线 智能解说',
      content:'远程交互，异地实时销售配合智能解说 ，随时解除用户疑虑' 
    },
    { 
      id:5,  
      src: require('@/assets/images/icon/vrPanorams-solution-warp3-img5.png'), 
      tit:'强势引流、高转化',
      content:'优惠券、门店券、积分等多种权益体系，为店铺强势引流' 
    },
    { 
      id:6,  
      src: require('@/assets/images/icon/vrPanorams-solution-warp3-img6.png'), 
      tit:'完善的开发者生态',
      content:'稳定的API对接，为商户提供更过服务选择' 
    },
  ],

  warp4: [
    { 
      id:1, 
      src: 'https://h5.ophyer.cn/official_website/other/VRmb1.png', 
      tit:'全景商城模板'
    },
    { id:2,  
      src: 'https://h5.ophyer.cn/official_website/other/VRmb2.png', 
      tit:'全景房产模板'
    },
    { id:3,  
      src: 'https://h5.ophyer.cn/official_website/other/VRmb3.png', 
      tit:'全景汽车模板'
    },
    { id:4,  
      src: 'https://h5.ophyer.cn/official_website/other/VRmb4.png', 
      tit:'智慧文旅模板'
    },
    { id:5,  
      src: 'https://h5.ophyer.cn/official_website/other/VRmb5.png', 
      tit:'智慧教育模板'
    },
    { id:6,  
      src: 'https://h5.ophyer.cn/official_website/other/VRmb6.png', 
      tit:'全景餐饮模板'
    },
    { id:7,  
      src: 'https://h5.ophyer.cn/official_website/other/VRmb7.png', 
      tit:'全景酒店模板'
    },
    { id:8,  
      src: 'https://h5.ophyer.cn/official_website/other/VRmb8.png', 
      tit:'游戏模板'
    },
  ],

}



const Shot = {
  tabList: [
    {
      id: 1,
      tabTitle: 'VR拍摄',
      img: 'https://h5.ophyer.cn/official_website/other/vrPanorama-shot-tabList01.png',
      lunTitle: '金牌摄影师专业拍摄',
      tip: '',
      text: [
        '国内知名摄影师提供一对一上门服务，高效率、专业级拍摄，完美还原真实场景',
      ]
    },
    {
      id: 2,
      tabTitle: '专业设备',
      img: 'https://h5.ophyer.cn/official_website/other/vrPanorama-shot-tabList02.png',
      lunTitle: '3C大片级专业设备',
      tip: '',
      text: [
        '国产、进口专业设备租赁，提供VR全景拍摄、视频拍摄、照片拍摄、3D环物拍摄',
      ]
    },
    {
      id: 3,
      tabTitle: '全景制作',
      img: 'https://h5.ophyer.cn/official_website/other/vrPanorama-shot-tabList03.png',
      lunTitle: '专业技术团队打造真实全景',
      tip: '',
      text: [
        '行业内10年深耕技术团队一对一定制化开发，致力于打造一比一真实场景',
      ]
    },
    {
      id: 4,
      tabTitle: '运营内容策划',
      img: 'https://h5.ophyer.cn/official_website/other/vrPanorama-shot-tabList04.png',
      lunTitle: '助力企业扩展运营方式',
      tip: '',
      text: [
        '十二年品牌运营经验资深运营专家为企业提供定制化VR全景运营策略，助力企业运营方式拓展',
      ]
    }
  ],

  warp2: [
    { 
      id:1, 
      src: require('@/../public/img/icons/solutionVR/VRicon1.png'), 
      tit:'专业团队',
      content:'深耕行业10年，更了解您的需求，5年以上顾问经验提供7*24小时专属服务，为您解决所有拍摄问题'
    },
    { 
      id:2,  
      src: require('@/../public/img/icons/solutionVR/VRicon2.png'), 
      tit:'高端设备' ,
      content:'涵盖所有型号国内设备及进口设备，为您提供全方位的拍摄服务与制作服务'
    },
    { 
      id:3,  
      src: require('@/../public/img/icons/solutionVR/VRicon3.png'), 
      tit:'丰富的运营经验',
      content:'飞天云动对线上、线下市场有着深度的了解和丰富的运营经验，并与头部渠道有着深度战略合作关系，协助品牌快速精准落地运营策略' 
    },
    { 
      id:4,  
      src: require('@/../public/img/icons/solutionVR/VRicon4.png'), 
      tit:'企业信赖',
      content:'深耕行业10年，众多合作伙伴，他们得选择源于对我们的信赖' 
    }  
  ],


  warp3: [
    { 
      id:1, 
      src: 'https://h5.ophyer.cn/official_website/other/vrPanorams-shot-warp3-img1.png', 
      tit:'强互动 重体验',
      content:'720°沉浸式体验+数字多媒体互动打造独特品牌形象'
    },
    { 
      id:2,  
      src: 'https://h5.ophyer.cn/official_website/other/vrPanorams-shot-warp3-img2.png', 
      tit:'拓客引流、拉新' ,
      content:'微信、H5、Web多渠道流量获客，搞定运营获客难题，拉新获客不用愁'
    },
    { 
      id:3,  
      src: 'https://h5.ophyer.cn/official_website/other/vrPanorams-shot-warp3-img3.png', 
      tit:'丰富运营礼赠场景',
      content:'海量运营场景，满足活动所需，让您与客户的距离再近一点' 
    },
    { 
      id:4,  
      src: 'https://h5.ophyer.cn/official_website/other/vrPanorams-shot-warp3-img4.png', 
      tit:'同屏连线 高效运营',
      content:'远程交互，异地实时销售配合智能解说 ，随时解除用户疑虑' 
    }
  ],

  warp4: [
    { 
      id:1, 
      src: 'https://h5.ophyer.cn/official_website/other/vrPanorams-shot-warp4-img1.png', 
      tit:'导播间'
    },
    { 
      id:2,  
      src: 'https://h5.ophyer.cn/official_website/other/vrPanorams-shot-warp4-img2.png', 
      tit:'休息区'
    },
    { 
      id:3,  
      src: 'https://h5.ophyer.cn/official_website/other/vrPanorams-shot-warp4-img3.png', 
      tit:'会议室'
    },
    { 
      id:4,  
      src: 'https://h5.ophyer.cn/official_website/other/vrPanorams-shot-warp4-img4.png', 
      tit:'化妆间'
    },
    { 
      id:5,  
      src: 'https://h5.ophyer.cn/official_website/other/vrPanorams-shot-warp4-img5.png', 
      tit:'摄影摄像'
    },
    { 
      id:6,  
      src: 'https://h5.ophyer.cn/official_website/other/vrPanorams-shot-warp4-img6.png', 
      tit:'设计策划'
    },
    { 
      id:7,  
      src: 'https://h5.ophyer.cn/official_website/other/vrPanorams-shot-warp4-img7.png', 
      tit:'演员灯光'
    },
    { 
      id:8,  
      src: 'https://h5.ophyer.cn/official_website/other/vrPanorams-shot-warp4-img8.png', 
      tit:'影视后期'
    },
  ],

}



export default {
  Home,
  Solution,
  Shot
}