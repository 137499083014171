<template>
  <v-app>
    <v-banner
      single-line
      height="400"
      class="banner"
    >
      <v-img
        src="https://h5.ophyer.cn/official_website/banner/vrPanorams-home-banner.png"
        height="100%"
        class="d-flex align-center"
        >
          <div class="banner-box">
            <p class="banner-title">VR数字空间体验 胜似“身临其境”</p>
            <p class="banner-text">产品、用户零距离，虚拟空间互动引领者</p>
            <v-btn
              rounded
              color="#0568FD"
              width="160"
              height="50"
              class="font-size-20 mt-5"
              @click.stop="handleBannerBtn()"
            >
              <span class="color-FFF">立即体验</span>
            </v-btn>
          </div>
      </v-img>
    </v-banner>
    
    <!-- VR全景系统 -->
    <v-card class="warps warp1" :flat="true">
      <div class="public-title">
        <p>VR PANORAMIC SYSTEM</p>
        <div>
          <span>VR全景系统</span>
          <span>720°沉浸式体验，胜似“身临其境”</span>
        </div>
      </div>
      <v-tabs v-model="tab" centered  color="#0084ff" class="g-tabs">
        <v-tab v-for="(v, k) in data.tabList" :key="k"  @click="clickItem(v.id)">
          <b>{{ v.tabTitle }}</b>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(v, k) in data.tabList" :key="k" :transition="false">
            <div class="box">
              <transition name="slide-fade" appear>
                <div class="left" v-show="tab == k">
                  <canvas v-show="v.id == 4" :class="'aroundImgPreview'+v.id" @mousedown="handleCanvasDown" @mousemove="handleCanvasMove" @mouseup="handleCanvasUp"></canvas>
                  <img :src='v.img' />
                </div>
              </transition>
              <transition name="slide-fade-reverse" appear>
                <div class="right" v-show="tab == k">
                  <v-card-text class="title" v-text="v.lunTitle" />
                  <v-card-text class="tip" v-text="v.tip" />
                  <v-card-text class="text" v-for="(i, j) in v.text" :key="j" v-text="i" />
                </div>
              </transition>
            </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>


    <!-- 数字商品+虚拟现实 打造一站式运营服务 -->
    <v-card class="warps warp2" :flat="true">
      <div class="public-title">
        <p>MARKETING SERVICES</p>
        <div>
          <span>数字商品+虚拟现实 打造一站式运营服务</span>
          <span>全集展示 + 运营转化  +  线上购物   +  全域运营</span>
        </div>
      </div>
      <div class="con">
        <v-row no-gutters justify='space-between'>
          <v-col v-for="(v, k) in data.warp2" :key="k" cols="10" sm="2" >
            <div class="box">
              <img :src='v.img' />
              <v-card-text class="tip pa-0"> {{ v.text }} </v-card-text>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>

    <!-- 数字商品+虚拟现实 打造一站式运营服务 -->
    <v-card class="warps warp3" :flat="true">
      <div class="public-title">
        <p>E-COMMERCE PLATFORM</p>
        <div>
          <span>适配主流电商平台 运营无界</span>
          <span>强互动 重效果 助力商家提升运营效果</span>
        </div>
      </div>
      <div class="con">
        <img src="https://h5.ophyer.cn/official_website/other/vrPanorams-home-warp3-img.png" />
      </div>
    </v-card>

    <!-- 行业案例 -->
    <!--<v-card class="warps warp4" flat>-->
    <!--  <div class="public-title">-->
    <!--    <p>CUSTOMER CASE</p>-->
    <!--    <div>-->
    <!--      <span>客户案例</span>-->
    <!--    </div>-->
    <!--  </div>-->
    <!--  <v-container fluid style="max-width: 1100px;">-->
    <!--    <v-row class="other-anli">-->
    <!--      <v-col v-for="(card, index) in data.anli" :key="index" cols="3">-->
    <!--        <v-card-->
    <!--          class="mx-auto item-card"-->
    <!--          max-width="240"-->
    <!--          max-height="410"-->
    <!--          color="transparent"-->
    <!--          @click="goCaseDetail(card)"-->
    <!--          flat-->
    <!--        >-->
    <!--          <v-img-->
    <!--            max-height="340"-->
    <!--            :src="card.coverImg"-->
    <!--            :aspect-ratio="9/16"-->
    <!--          >-->
    <!--          <template v-slot:placeholder>-->
    <!--            <v-row class="fill-height ma-0" align="center" justify="center">-->
    <!--              <v-progress-circular-->
    <!--                indeterminate-->
    <!--                size="80"-->
    <!--                color="red lighten-5"-->
    <!--              ></v-progress-circular>-->
    <!--            </v-row>-->
    <!--          </template>-->
    <!--          </v-img>-->
    <!--          <div class="text-box">-->
    <!--            <v-card-text class="title font-size-14 font-weight-bold" v-text="card.itemTitle"></v-card-text>-->
    <!--          </div>-->
    <!--        </v-card>-->
    <!--      </v-col>-->
    <!--    </v-row>-->
    <!--  </v-container>-->
    <!--</v-card>-->
 
  </v-app>
</template>

<script>
import { browserVersion } from "@/utils/utils";
import textData from './textData.js'
import qs from 'qs'
// import FramePlayer from 'frame-player';
const FramePlayer = () => import('frame-player');
export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      data: textData.Home,
      tab: null,
      vm: this,
      bannerList: [
        {
          img: "https://h5.ophyer.cn/official_website/banner/vrPanorams-home-banner.png",
          title: ["VR数字空间体验 胜似“身临其境”"],
          titleColor:"#FFFFFF",
          tip: ["产品、用户零距离，虚拟空间互动引领者"],
          btn:'立即体验'
        },
      ],
      framePlayer: null, //360物体预览对象
      around_play: false, //360物体播放控制
      around_scale: 100,
      sanliuleng: null,
      imgArr: [
        'https://h5.ophyer.cn/official_website/other/vrPanorams-xuliezheng-01.png',
        'https://h5.ophyer.cn/official_website/other/vrPanorams-xuliezheng-02.png',
        'https://h5.ophyer.cn/official_website/other/vrPanorams-xuliezheng-03.png',
        'https://h5.ophyer.cn/official_website/other/vrPanorams-xuliezheng-04.png',
        'https://h5.ophyer.cn/official_website/other/vrPanorams-xuliezheng-05.png',
        'https://h5.ophyer.cn/official_website/other/vrPanorams-xuliezheng-06.png',
        'https://h5.ophyer.cn/official_website/other/vrPanorams-xuliezheng-07.png',
        'https://h5.ophyer.cn/official_website/other/vrPanorams-xuliezheng-08.png',
        'https://h5.ophyer.cn/official_website/other/vrPanorams-xuliezheng-09.png',
        'https://h5.ophyer.cn/official_website/other/vrPanorams-xuliezheng-10.png',
        'https://h5.ophyer.cn/official_website/other/vrPanorams-xuliezheng-11.png',
        'https://h5.ophyer.cn/official_website/other/vrPanorams-xuliezheng-12.png',
        'https://h5.ophyer.cn/official_website/other/vrPanorams-xuliezheng-13.png',
        'https://h5.ophyer.cn/official_website/other/vrPanorams-xuliezheng-14.png',
      ],
      isIE: browserVersion(),
      urlLink: window.location.host === 'wmy.ophyer.com' ? process.env.VUE_APP_PRODUCTION_DOMAIN : process.env.VUE_APP_TEST_DOMAIN
    }
  },
  watch: {
  },
  created() {
    console.log(this.isIE,'this.isIEthis.isIE')
     if(this.isIE) return;
    this.newFramePlayer();
  },
  methods: {
    goCaseDetail(key) {
      let query = {
        source: 'qita',
        anli: key.self,
      }
      let url = '/CaseDetail?'+qs.stringify(query)
      window.open(url, '_blank')
    },
    handleBannerBtn() {
      window.open(this.urlLink.split(",")[3]+'/indexService?source=gwvr', '_blank')
    },
    goLink() {
      window.open('/', '_blank')
    },

    clickItem(id) {
      console.log(id)
      this.sanliuleng = id
      let _this = this
      setTimeout(()=>{
        _this.newFramePlayer();
      },100)
    },
    aroundPlay(val) {
      // this.newFramePlayer();
      if(this.framePlayer){
        if(val){
          this.framePlayer.play();
        }else {
          this.framePlayer.stop();
        }
      }
    },
    newFramePlayer() {
      if(this.sanliuleng != 4) return
      this.clearFramePlayer();
      this.$nextTick(()=>{
        this.framePlayer = new FramePlayer({
          canvas: ".aroundImgPreview4",
          width: 400,
          height: 260,
          images: this.imgArr,
          fps: 5,
          loop: -1,
          transparent: false,
          autoplay: false
        });
      console.log(this.framePlayer)
        this.framePlayer.on("ready",()=>{
          // this.around_play = true;
          // this.framePlayer.play(0);
          this.framePlayer.play(0);
          console.log(this.framePlayer)
        });
        this.framePlayer.on("update",(options)=>{
          this.frame = options.frame;
        });
      })
    },
    clearFramePlayer() {
      this.around_play = false;
      if(this.framePlayer){
        this.framePlayer.off("ready");
        this.framePlayer.off("update");
        this.framePlayer.stop();
        this.framePlayer = null;
      }
    },
    handleCanvasDown(e) {
      this.canvasDown = true;
      this.coordinate.x = e.clientX;
      this.coordinate.y = e.clientY;
    },
    handleCanvasMove(e) {
      if(this.canvasDown){
        if(e.clientX - this.coordinate.x > 5){
          if(this.frame === this.imgArr.length - 1){
            this.frame = 0;
          }else {
            this.frame++;
          }
          this.coordinate.x = e.clientX;
        }else if(this.coordinate.x - e.clientX > 5){
          if(this.frame === 0){
            this.frame = this.imgArr.length - 1;
          }else {
            this.frame--;
          }
          this.coordinate.x = e.clientX;
        }
        this.coordinate.y = e.clientY;
      }
    },
    handleCanvasUp (e){
      console.log(e)
      this.canvasDown = false;
    },
  }
}
</script>

<style lang="scss" scoped>

.banner{
  //margin-top: 60px;
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
  .banner-box {
    color: #fff;
    text-align: center;
    .banner-title { 
      font-size: 48px;
      font-weight: 400;
      line-height: 53px;
    }
    .banner-text {
      margin-top: 15px;
      font-size: 18px;
      line-height: 18px;
    }
  }
 
  
}

.warps {
  margin: 0 auto;
  text-align: center;
  width: 1200px !important;
}


.warp1 {
  .public-title {
    margin-top: 40px;
  }
  .g-tabs {
    margin-top: 20px;
  }
  .box {
    width: 1000px;
    margin: 40px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .left {
    margin-right: 55px;
    position: relative;
    img {
      width: 400px;
      height: 260px;
      border-radius: 30px;
    }
  }
  .right{
    text-align: start;
    .v-card__text {
      padding: 0;
    }
    .title {
      font-size: 24px !important;
      font-weight: 600 !important;
    }
    .tip {
      margin: 12px 0 35px;
      font-size: 16px !important;
    }
    .text {
      font-size: 14px;
      line-height: 26px;
      color: #666;
    }
  }
}

.warp2 {
  .con {
    margin-top: 50px;
  }
  .box {
    // width: 140px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
    img {
      width: 120px;
      height: 120px;
      margin-bottom: 25px;
    }
    .tip {
      color: #666;
      width: 140px;
      font-size: 14px;
    }
  }
}

.warp3 {
  .con {
    margin-top: 30px;
  }
}

.warp4 {
  margin-bottom: 30px;
  .public-title {
    margin-bottom: 10px;
  }
}


#aroundImgPreview, .aroundImgPreview4 {
  width: 400px;
  height: 260px !important;
  border-radius: 30px;
  position: absolute;
  bottom: 0;
}
</style>
